import React from 'react'
import { Dropdown, Image, Navbar } from 'react-bootstrap'
import tots from '../../styles/images/remora.png'
import userStore from '../../stores/userStore'

export default function Header() {
  const authLogout = userStore((state: any) => state.authLogout)
  const dataStore = userStore((state: any) => state)

  const [isDarkMode, setIsDarkMode] = React.useState(false)

  const companyName =
    dataStore?.company &&
    dataStore?.company?.some((company: any) => company.name === 'CEDIS') &&
    dataStore?.company?.length === 11
      ? 'ALL COMPANY'
      : dataStore?.company?.length === 1
      ? dataStore?.company && dataStore?.company[0]?.name
      : dataStore?.company &&
        dataStore?.company?.length > 1 &&
        dataStore?.roles &&
        dataStore?.roles[0]?.name === 'transporteur'
      ? 'LOGIPOL'
      : ''

  React.useEffect(() => {
    if (isDarkMode) {
      document.body.setAttribute('data-bs-theme', 'dark')
    } else {
      document.body.setAttribute('data-bs-theme', 'light')
    }

    return () => {
      document.body.removeAttribute('data-bs-theme')
    }
  }, [isDarkMode])

  return (
    <header className='sticky-top border-bottom '>
      <Navbar className='bg-body-tertiary px-3 py-0 py-md-2'>
        <Navbar.Brand className='d-flex align-items-center'>
          <Image src={tots} width={50} alt='logo' className='me-2' />
          <div className='responsive-font-small border border-secondary rounded-pill px-3'>
            {companyName}
          </div>
        </Navbar.Brand>
        <div className='d-flex ms-auto'>
          <div className='d-flex align-items-center me-4'>{dataStore.name}</div>
          <div>
            <Dropdown>
              <Dropdown.Toggle
                variant='transparent'
                id='dropdown-basic'
                className='border-0 no-chevron'
              >
                <i className='ri-more-2-line fs-4'></i>
              </Dropdown.Toggle>
              <Dropdown.Menu align='end'>
                <div className='px-3'>
                  <i className='ri-vuejs-fill fs-5'></i> 1.0.1
                </div>
                <Dropdown.Divider />
                <Dropdown.Item href='/compagnies' className='d-block d-md-none'>
                  <i className='ri-community-line fs-5'></i> Compagnies
                </Dropdown.Item>
                <Dropdown.Item href='/utilisateurs' className='d-block d-md-none'>
                  <i className='ri-user-line fs-5'></i> utilisateurs
                </Dropdown.Item>
                <Dropdown.Item
                  className='text-secondary'
                  onClick={() => setIsDarkMode(!isDarkMode)}
                >
                  <i className={`ri-${!isDarkMode ? 'moon' : 'sun'}-line fs-4`}></i> Thème
                </Dropdown.Item>
                <Dropdown.Item href='/connexion' onClick={authLogout}>
                  <i className='ri-logout-box-r-line fs-5'></i> Déconnexion
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </Navbar>
    </header>
  )
}
