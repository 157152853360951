import React from 'react'
import {
  Alert,
  Badge,
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Image,
  InputGroup,
  Modal,
  Row,
  Spinner,
  Table,
} from 'react-bootstrap'
import { DetailConnaissementModal } from '../../component/ui/Modal/Modals'
import { Tag } from 'rsuite'
import { _tagStatus } from '../../utils/functions'
import ConnaissementService from '../../services/connaissements/ConnaissementServices'
import OrdersService from '../../services/orders/OrdersService'
import userStore from '../../stores/userStore'
import { errorType } from '../../definitions/errorType'
import { ToastDeleteSuccess, ToastError, ToastInfo, ToastUpdateUserSuccess } from '../../component/ui/Toast/Toastes'
import {
  DeleteModal,
  QrcodeConnaissementModal,
  UpdateMultiToDemandeModal,
  UpdateToDemandeModal,
} from '../../component/ui/Modal/ConnaissementModals'
import { _refreshToken } from '../../utils/api/apiControlerFunctions'
import { useReactToPrint } from 'react-to-print'
import noResult from '../../styles/images/no_result.png'
import ErrorMessage from '../../component/ui/error/ErrorMessage'

// import connaissemnentDatazz from '../../data/connaissements/connaissement.json'

import PaginationZero from '../../component/ui/PaginationZero'
import BillOfTableTitle from '../../component/billOfLading/BillOfTableTitle'
import BillOfFilter from '../../component/billOfLading/BillOfFilter'
import ItemsLimiter from '../../component/billOfLading/ItemsLimiter'
import ConnaissementServices from '../../services/connaissements/ConnaissementServices'

export default function BillOfLading() {
  const dataStore = userStore((state: any) => state)

  const isTransporter : boolean = dataStore?.roles && dataStore?.roles[0]?.name === 'transporteur'

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isErrorConnaiss, setIsErrorConnaiss] = React.useState<errorType>({
    error: false,
    message: '',
  })
  const [isError, setIsError] = React.useState<errorType>({
    error: false,
    message: '',
  })
  const [infoOrder, setInfoOrder] = React.useState<string>("")
  const [isFiltering, setIsFiltering] = React.useState<boolean>(false)

  const [connaissementData, setConnaissementData] = React.useState<any>([])
  const [selectedConnaissement, setSelectedConnaissement] = React.useState<any>({})
  const [checkedMultiConnaissement, setCheckedMultiConnaissement] = React.useState<any>([])
  const [checkedConnaissement, setCheckedConnaissement] = React.useState<any>([])
  const [idsOrder, setIdsOrder] = React.useState<any>([])
  const [filteringData, setFilteringData] = React.useState<any>({
    numeroConnaissement: '',
    expediteur_denomination: '',
    destinataire: '',
    idNavire: '',
    evenementConnaissement: '',
    dateDepart: '',
    nomIleArrivee: '',
    dateArrivee: '',
  })
  const [nbPalette, setNbPalette] = React.useState<number>(0)

  const [currentPage, setCurrentPage] = React.useState<number>(0)
  const [totalPages, setTotalPages] = React.useState<number>(0)
  const [itemPerPage, setItemPerPage] = React.useState<number>(10)

  const isEmpty =
    filteringData?.numeroConnaissement === '' &&
    filteringData?.expediteur_denomination === '' &&
    filteringData?.destinataire === '' &&
    filteringData?.idNavire === '' &&
    filteringData?.evenementConnaissement === '' &&
    filteringData?.dateDepart === '' &&
    filteringData?.nomIleArrivee === '' &&
    filteringData?.dateArrivee === ''

   

  //////////////////
  //Toastes
  /////////////////
  //Toast Delete success
  const [showDeleteSuccess, setShowDeleteSuccess] = React.useState<boolean>(false)
  const toggleShowDeleteSuccess = () => setShowDeleteSuccess(!showDeleteSuccess)

  const [showUpdateSuccess, setShowUpdateSuccess] = React.useState<boolean>(false)
  const toggleShowUpdateSuccess = () => setShowUpdateSuccess(!showUpdateSuccess)

  const [showOrderError, setShowOrderError] = React.useState<boolean>(false)
  const toggleShowOrderError = () => setShowOrderError(!showOrderError)

  const [showInfo, setShowInfo] = React.useState<boolean>(false)
  const toggleShowInfo = () => setShowInfo(!showInfo)

  //////////////////
  //Modals
  /////////////////
  //detail connaissement
  const [show, setShow] = React.useState(false)
  const handleClose = () => {
    setShow(false)
  }
  const handleShow = () => setShow(true)

  //update palette count
  const [showUpdatePalette, setShowUpdatePalette] = React.useState(false)
  const handleCloseUpdatePalette = () => {
    setShowUpdatePalette(false)
  }
  const handleShowUpdatePalette = () => setShowUpdatePalette(true)

  //Show qrcode
  const [showQrcode, setShowQrcode] = React.useState(false)
  const handleCloseQrcode = () => {
    setShowQrcode(false)
  }
  const handleShowQrcode = () => setShowQrcode(true)

  //Update connaissement to demande
  const [showUpdateToDemandeModal, setShowUpdateToDemandeModal] = React.useState(false)
  const handleCloseUpdateToDemandeModal = () => {
    setIdsOrder([])
    setShowUpdateToDemandeModal(false)
  }
  const handleShowUpdateToDemandeModal = () => {
    setShowUpdateToDemandeModal(true)
  }

  //Update multi connaissement to demande
  const [showUpdateMultiToDemandeModal, setShowUpdateMultiToDemandeModal] =
    React.useState(false)
  const handleCloseUpdateMultiToDemandeModal = () => {
    setShowUpdateMultiToDemandeModal(false)
  }
  const handleShowUpdateMultiToDemandeModal = () => {
    getOrderSByIDBill()
    setShowUpdateMultiToDemandeModal(true)
  }

  //Delete connaissement
  const [showDeleteModal, setShowDeleteModal] = React.useState(false)
  const handleCloseDeleteModal = () => {
    setIdsOrder([])
    setShowDeleteModal(false)
  }
  const handleShowDeleteModal = () => {
  
    setShowDeleteModal(true)}

  
  const printRef = React.useRef(null)

  // Utiliser useReactToPrint pour gérer l'impression
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `${selectedConnaissement?.numero}`, // Titre du document imprimé
  })

  React.useEffect(() => {
    connaissementDataTable(currentPage, setTotalPages, itemPerPage)
  }, [])

  React.useEffect(() => {
    if (isEmpty) {
      connaissementDataTable(currentPage, setTotalPages, itemPerPage)
      setIsFiltering(false)
    }
  }, [filteringData])

  React.useEffect(() => {
    connaissementDataTable(currentPage, setTotalPages, itemPerPage)
  }, [currentPage, itemPerPage])

  const getOrderSByIDBill = () => {
    setIsError({
      error: false,
      message: '',
    })
    try {
      const orderPromises = checkedConnaissement?.map((id: number) => {
      return  OrdersService.getOrdersByIdConnaissement(dataStore.token, id)
          .then((response: any) => {
            const checkedOrder = response?.data?.data?.map((order: any) => {
            return  idsOrder.push(order?.id)
            })
            console.log(checkedOrder)
          })
          .catch((error: any) => {
            setIsError({
              error: true,
              message: error?.response?.data?.message,
            })
            toggleShowOrderError()
          })
      })

      console.log(orderPromises)
    } catch (error) {
      console.log(error)
    }
  }

  const filterConnaissement = async (token: any,  filteringData: any) => {

     // Filtrer les paramètres qui ne sont pas définis ou sont vides
     const filteredParams = Object.entries(filteringData)
     .filter(([key, value]) => value) // Garde les paires où la valeur est définie (non null, non undefined, non vide)
     .map(([key, value]: any) => `${key}=${encodeURIComponent(value)}`) // Encode chaque paramètre
     .join('&'); // Les concatène avec '&'

     setIsLoading(true)


    try{

      const responseFilteredConnaissement = await ConnaissementService.getFilteredConnaissement(token, 0, filteredParams)
      const sortedData = responseFilteredConnaissement?.data?.content?.sort((a: any, b: any) => b.id - a.id)
      setTotalPages(responseFilteredConnaissement?.data?.totalPages)
      setConnaissementData(sortedData)
      setIsLoading(false)
      setIsFiltering(true)
    }catch(error){
      setIsLoading(false)
      console.log(error)
      setIsFiltering(true)
    }
  }

  const handleFilterConnaissement = (event: any) => {
    const value = event?.target?.value
    if (value?.length > 2) {
      const filteredData = connaissementData.filter((item: any) => {
        return (
          item?.destinataire?.denomination?.toLowerCase().includes(value.toLowerCase()) ||
          item?.numero?.toLowerCase().includes(value.toLowerCase()) ||
          item?.dernierEtat?.evenementConnaissement
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          item?.ileArrivee?.nom?.toLowerCase().includes(value.toLowerCase()) ||
          item?.voyage?.dateDepart?.toLowerCase().includes(value.toLowerCase()) ||
          item?.voyage?.nomNavire?.toLowerCase().includes(value.toLowerCase()) ||
          item?.voyage?.periple[0]?.dateArrivee?.toLowerCase().includes(value.toLowerCase())
        )
      })

      setConnaissementData(filteredData)
    }
    if (value.length === 0) {
      connaissementDataTable(0, setTotalPages, itemPerPage)
    }
  }

  const connaissementDataTable = async (currentPage: number, setTotalPages: any, itemPerPage: number) => {
    setIsLoading(true)
    try {
      const response = await ConnaissementService.getConnaissement(
        dataStore.access_token,
        currentPage,
        itemPerPage
      )
      if (response?.status === 200) {
        setIsErrorConnaiss({
          error: false,
          message: '',
        })
      }
      const sortedData = response?.data?.content?.sort((a: any, b: any) => b.id - a.id)
      // console.log(response?.data)
      setTotalPages(response?.data?.totalPages)
      setConnaissementData(sortedData)
      setIsLoading(false)
    } catch (error: any) {
      console.log(error)
      setIsError({
        error: true,
        message: error?.response?.data?.error ? error?.response?.data?.error :  error?.message === "Network Error" ? "Oups, une erreur du côté de Revatua est survenue. Veuillez réessayer plus tard." : error?.message
      })
      setIsLoading(false)

      if (error?.response?.data?.error === 'invalid_token') {
        setIsError({
          error: true,
          message: error?.response?.data?.error
        })
        const idCompany = dataStore?.company && dataStore?.company[0]?.id_company
        _refreshToken(dataStore?.token, idCompany)
      }
    }
  }

  const updateBrouillonConnaissement = async (token: string, id: number) => {
    const bodyData = {
      evenementConnaissementEnum: 'DEMANDE',
      demandeParArmateur: false,
    }

    try {
      const response = await ConnaissementService.updateConnaissement(token, bodyData, id)
      // console.log(response)
      if (response.status === 200) {
        connaissementDataTable(currentPage, setTotalPages, itemPerPage)
        toggleShowUpdateSuccess()
        handleCloseUpdateToDemandeModal()
        setCheckedConnaissement([])
      }
    } catch (error: any) {
      console.log(error)
      setIsError({
        error: true,
        message: error?.response?.data?.error ? error?.response?.data?.error :  error?.message === "Network Error" ? "Oups, une erreur du côté de Revatua est survenue. Veuillez réessayer plus tard." : error?.message
      })
    }
  }
  
  const updateNbPaletteConnaissement = async (token: string, data: any) => {
    const connaissDetail = data.detailConnaissements?.map((prod: any) => {
      return {
        codeSH: prod?.codeSH.nomenclature,
        codeTarif: prod?.codeTarif.code,
        description: prod?.description,
        nbColis: prod?.nbColis,
        poids: prod?.poids,
        stockage: prod?.stockage,
        unitePoids: prod?.unitePoids,
      }
    })


    const bodyData = {
      "version": data?.version,
      "detailConnaissementDTO": connaissDetail,
      "expediteur": data?.expediteur,
      "destinataire": data?.destinataire,
      "paiement": data?.paiement,
      "numeroVoyage": data?.voyage?.numero,
      "ileDepart": data?.ileDepart.nom,
      "lieuDepart": data?.lieuDepart.nom,
      "ileArrivee": data?.ileArrivee.nom,
      "lieuArrivee": data?.lieuArrivee.nom,
      "nombreColisAEmbarquer": nbPalette

    }

    try {

      const response = await ConnaissementService.updateNbPalette(token, bodyData, data?.id)
      if (response.status === 200) {
        toggleShowUpdateSuccess()
        handleCloseUpdatePalette()
        setCheckedConnaissement([])
      }
    } catch (error: any) {
      console.log(error)
      const errorMessage =
        error?.message === 'Network Error'
          ? 'Oups, une erreur du côté de Revatua est survenue. Veuillez vérifier votre connexion et réessayer plus tard.'
          : error?.response?.data?.error || error?.response?.data?.message

          const errorDetail = Array.isArray(error?.response?.data?.detail)
          ? error?.response?.data?.detail[0]
            ? ` : ${error?.response?.data?.detail[0]?.origine || ''} ${error?.response?.data?.detail[0]?.cause || ''}`
            : ''
          : typeof error?.response?.data?.detail === 'string'
            ? ` : ${error?.response?.data?.detail}`
            : '';
      setIsError({
        error: true,
        message: `${errorMessage || error?.message}${errorDetail}`
      })
   
    }
  }

  const deleteBrouillonConnaissement = async (token: string, id: number) => {
    setIsError({
      error: false,
      message: "",
    })
    try {
      const response = await ConnaissementService.deleteBrouillon(token, id)
      // console.log(response)
      if (response.status === 204) {
        connaissementDataTable(0, setTotalPages, itemPerPage)
        toggleShowDeleteSuccess()
        handleCloseDeleteModal()
      }
    } catch (error: any) {
      console.log(error)
      setIsError({
        error: true,
        message: error?.response?.data?.message,
      })
      toggleShowOrderError()
    }
  }

  //Gère les connaissements selectionnées
  const handleSelectConnaissement = (bill: any) => {
    if (checkedConnaissement.includes(bill)) {
      // ()
      setCheckedConnaissement(checkedConnaissement?.filter((item: any) => item !== bill))
    } else {
      setCheckedConnaissement([...checkedConnaissement, bill])
    }
  }
  const multiselected = (bill: any) => {
    if (checkedMultiConnaissement.includes(bill)) {
      setCheckedMultiConnaissement(checkedMultiConnaissement?.filter((item: any) => item?.id !== bill?.id))
    } else {
      setCheckedMultiConnaissement([...checkedMultiConnaissement, {"id": bill?.id, "idEven": bill?.dernierEtat?.id, "num": bill?.numero}])
    }
  }
  const [pdfData, setPdfData] = React.useState<any>();

  const getPDFConnaissementByEvenement = (token: string, 
   setPdfData: any ) => {
    try{
      const pdfResponses = checkedMultiConnaissement?.map((item: any) => {

        return ConnaissementServices.getPDFConnaissement(token, item?.id, item?.idEven, item?.num, setPdfData)
      })
      // const response = ConnaissementServices.getPDFConnaissement(token, id, idEven, num, setPdfData)
      console.log(pdfResponses)
    }catch(error){
      console.log(error)
    }
  }


  //sélectionner tous les connaissements "BROUILLON"
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const validedData = connaissementData
        .filter((connaiss: any) => connaiss.dernierEtat.evenementConnaissement === 'BROUILLON')
        ?.map((item: any) => item.id)

      setCheckedConnaissement(validedData)
    } else {
      setCheckedConnaissement([])
    }
  }

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  const billOfilterProps = { handleSelectAll, filteringData, setFilteringData, isTransporter, filterConnaissement, isFiltering, isEmpty, isLoading}


//Modals props
  const detailOrderModalProps = {
    show,
    selectedConnaissement,
    handleClose,
    handlePrint,
    printRef,
  }
  const qrCodeModalProps = { showQrcode, selectedConnaissement, handleCloseQrcode }

  const updateToDemandeModalProps = {
    showUpdateToDemandeModal,
    handleCloseUpdateToDemandeModal,
    selectedConnaissement,
    updateBrouillonConnaissement,
    idsOrder,
    setIdsOrder,
    setIsError,
    toggleShowOrderError,
  }
  const updateMultiToDemandeModalProps = {
    showUpdateMultiToDemandeModal,
    handleCloseUpdateMultiToDemandeModal,
    checkedConnaissement,
    setCheckedConnaissement,
    connaissementDataTable,
    toggleShowUpdateSuccess,
    setIsError,
    toggleShowOrderError,
    idsOrder,
    setIdsOrder,
  }
  const deleteBillOfLadingProps = {
    showDeleteModal,
    handleCloseDeleteModal,
    selectedConnaissement,
    deleteBrouillonConnaissement,
    idsOrder, setIdsOrder,
    setIsError,
    toggleShowOrderError,
  }
  const toastUpdateUserSuccessProps = { showUpdateSuccess, toggleShowUpdateSuccess }
  const toastDeleteSuccessProps = { showDeleteSuccess, toggleShowDeleteSuccess }
  const toastErrorProps = {showOrderError, toggleShowOrderError, isError}; 
  const toastInfoProps = {showInfo, toggleShowInfo, infoOrder}; 



  return (
    <div className='p-1 p-sm-3'>
      <h3 className='text-secondary'>Connaissements</h3>
      <div>
        <Form.Group className='mb-3' controlId='searchBar'>
          <InputGroup className=''>
            <InputGroup.Text id='basic-addon1' className='bg-secondary border'>
              <i className='ri-search-line text-light'></i>
            </InputGroup.Text>
            <Form.Control
              className='border'
              type='text'
              autoComplete='on'
              placeholder='Recherche'
              onChange={handleFilterConnaissement}
            />
          </InputGroup>
        </Form.Group>
      </div>
      {checkedConnaissement?.length > 1 && (
        <>
          {/* <>
              <Badge bg='dark' className='m-auto fab2 rounded-circle primary-colo mb-5'>
                {checkedConnaissement?.length}
              </Badge>
              <Button
                variant='transparent'
                className='fab rounded-pill bg-violet mb-5 '
                onClick={() =>
                  getPDFConnaissementByEvenement(dataStore?.access_token, setPdfData)
                }
              >
                <i className='ri-download-2-line'></i> <span>Multi-download</span>
              </Button>
            </> */}
          <Badge
            bg='dark'
            className='m-auto fab2 rounded-circle primary-colo'
            onClick={handleShowUpdateMultiToDemandeModal}
          >
            {checkedConnaissement?.length}
          </Badge>
          <Button
            variant='transparent'
            className='fab rounded-pill bg-green '
            onClick={handleShowUpdateMultiToDemandeModal}
          >
            <i className='ri-check-double-line'></i> <span>Multi-validation</span>
          </Button>
        </>
      )}

      <Table striped hover responsive className='responsive-font-small border'>
        <BillOfTableTitle isTransporter={isTransporter} />
        <BillOfFilter billOfilterProps={billOfilterProps} />
        <tbody>
          {!isLoading &&
            connaissementData?.length > 0 &&
            connaissementData?.map((connaissement: any, indx: number) => {
              const isNotBrouillon =
                connaissement?.dernierEtat?.evenementConnaissement !== 'BROUILLON' 
                // &&
                // connaissement?.dernierEtat?.evenementConnaissement !==
                //   'OFFICIALISE_SOUS_RESERVE' &&
                // connaissement?.dernierEtat?.evenementConnaissement !== 'OFFICIALISE'
              const isDemande =
                connaissement?.dernierEtat?.evenementConnaissement === 'DEMANDE' ||
                connaissement?.dernierEtat?.evenementConnaissement === 'OFFICIALISE' ||
                connaissement?.dernierEtat?.evenementConnaissement === 'OFFICIALISE_SOUS_RESERVE'
              return (
                <tr key={indx} className=''>
                  <td
                    className={`p-1 p-sm-2 ${
                      connaissement?.dernierEtat?.motif !== null &&
                      connaissement?.dernierEtat?.evenementConnaissement ===
                        'DEMANDE_REFUSEE' &&
                      'bg-danger'
                    }`}
                    onClick={() => {
                      if (isNotBrouillon) {
                        setInfoOrder("Impossible de sélectionner un connaissement qui n’est pas à l’état de brouillon")
                        toggleShowInfo()
                      }
                    }}
                  >
                    <Form.Check
                      type='checkbox'
                      id={`${connaissement.id}`}
                      onChange={() => {
                        handleSelectConnaissement(connaissement?.id)
                        multiselected(connaissement)
                      }}
                      checked={
                        checkedConnaissement &&
                        checkedConnaissement?.includes(connaissement.id)
                      }
                      value={connaissement.id}
                      disabled={isNotBrouillon}
                      required
                    />
                  </td>
                  <td
                    onClick={() => {
                      setSelectedConnaissement(connaissement)
                      handleShow()
                    }}
                    className='pointer p-1 pe-0 p-md-2'
                  >
                    {connaissement?.numero ? connaissement?.numero : connaissement?.id}{' '}
                  </td>
                  {isTransporter && (
                    <td
                      onClick={() => {
                        setSelectedConnaissement(connaissement)
                        handleShow()
                      }}
                      className='pointer p-1 pe-0 p-md-2'
                    >
                      {connaissement?.expediteur?.denomination}{' '}
                    </td>
                  )}
                  <td
                    onClick={() => {
                      setSelectedConnaissement(connaissement)
                      handleShow()
                    }}
                    className='pointer p-1 pe-0 p-md-2'
                  >
                    {connaissement?.destinataire?.denomination}{' '}
                  </td>
                  <td
                    onClick={() => {
                      setSelectedConnaissement(connaissement)
                      handleShow()
                    }}
                    className='pointer p-1 pe-0 p-md-2'
                  >
                    {connaissement?.voyage?.nomNavire}
                  </td>
                  <td
                    onClick={() => {
                      setSelectedConnaissement(connaissement)
                      handleShow()
                    }}
                    className='pointer p-1 pe-0 p-md-2 responsive-font-small'
                  >
                    <Tag
                      className='responsive-font-small'
                      size='sm'
                      color={_tagStatus(connaissement?.dernierEtat?.evenementConnaissement)}
                    >
                      {connaissement?.dernierEtat?.evenementConnaissement}
                      {/* {connaissement?.dernierEtatOfficialise?.evenementConnaissement} */}
                    </Tag>
                  </td>
                  <td
                    onClick={() => {
                      setSelectedConnaissement(connaissement)
                      handleShow()
                    }}
                    className='pointer p-1 pe-0 p-md-2'
                  >
                    {connaissement?.voyage?.dateDepart}
                  </td>
                  <td
                    onClick={() => {
                      setSelectedConnaissement(connaissement)
                      handleShow()
                    }}
                    className='pointer p-1 pe-0 p-md-2'
                  >
                    {connaissement?.ileArrivee?.nom}
                  </td>
                  <td
                    onClick={() => {
                      setSelectedConnaissement(connaissement)
                      handleShow()
                    }}
                    className='pointer p-1 pe-0 p-md-2'
                  >
                    {connaissement?.voyage?.periple[0].dateArrivee}
                  </td>
                  <td className='pointer p-1 pe-0 p-md-2 text-center'>
                    <Row>
                      <Col>
                        {isDemande && (
                          <span
                            className=''
                            onClick={() => {
                              setSelectedConnaissement(connaissement)
                              handleShowQrcode()
                            }}
                          >
                            <i className='ri-qr-code-line fs-4 me-2'></i>
                          </span>
                        )}
                      </Col>
                      {!isTransporter && (
                        <Col>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant='transparent'
                              id='dropdown-basic'
                              className='border-0 no-chevron'
                            >
                              <b>
                                {' '}
                                <i className='ri-more-2-line'></i>
                              </b>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align='end'>
                              {isNotBrouillon && (
                                <Dropdown.Item
                                  className='d-flex align-items-center '
                                  onClick={() => {
                                    setSelectedConnaissement(connaissement)
                                    handleShowQrcode()
                                  }}
                                >
                                  <i className='ri-qr-code-line fs-4 me-2'></i> Qrcode
                                </Dropdown.Item>
                              )}
                              <Dropdown.Item
                                className='d-flex align-items-center '
                                onClick={() => {
                                  setSelectedConnaissement(connaissement)
                                  handleShow()
                                }}
                              >
                                <i className='ri-file-list-2-line text-info fs-4 me-2'></i>{' '}
                                Détail
                              </Dropdown.Item>
                              {!isNotBrouillon && (
                                <>
                                  <Dropdown.Item
                                    className='d-flex align-items-center '
                                    onClick={() => {
                                      handleShowUpdateToDemandeModal()
                                      setSelectedConnaissement(connaissement)
                                    }}
                                  >
                                    <i className='ri-check-line fs-4 me-2 text-success'></i>{' '}
                                    BROUILLON{' '}
                                    <i className='ri-arrow-right-line text-primary'></i>
                                    DEMANDE
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className='d-flex align-items-center '
                                    onClick={() => {
                                      handleShowUpdatePalette()
                                      setSelectedConnaissement(connaissement)
                                    }}
                                  >
                                    <i className='ri-pencil-line fs-4 me-2 text-warning'></i>{' '}
                                    Modifier{' '}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className='d-flex align-items-center '
                                    onClick={() => {
                                      handleShowDeleteModal()
                                      setSelectedConnaissement(connaissement)
                                    }}
                                  >
                                    <i className='ri-close-circle-line fs-4 me-2 text-danger'></i>{' '}
                                    Supprimer
                                  </Dropdown.Item>
                                </>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      )}
                    </Row>
                  </td>
                </tr>
              )
            })}

          {!isLoading && !isError.error && isFiltering && connaissementData?.length === 0 && (
            <tr className=''>
              <td></td>
              <td colSpan={7} className='text-center'>
                <Image src={noResult} height={32} /> Votre recherche n'a donné aucun résultat
              </td>
              <td></td>
            </tr>
          )}

          {!isLoading && isErrorConnaiss.error && (
            <tr className=''>
              <td colSpan={isTransporter ? 10 : 9} className='text-center'>
                <Alert variant='danger'>
                  {isErrorConnaiss?.message}
                </Alert>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className='d-flex align-items-center mb-5'>
        <PaginationZero
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
        <ItemsLimiter itemPerPage={itemPerPage} setItemPerPage={setItemPerPage} />
      </div>

      {isLoading && (
        <Container
          fluid
          className='bg-body-tertiary d-flex justify-content-center align-items-center vh-100 text-center'
        >
          <Spinner variant='primary' /> <span className='ms-3'>Loading...</span>
        </Container>
      )}

      <DetailConnaissementModal detailOrderModalProps={detailOrderModalProps} />
      <QrcodeConnaissementModal qrCodeModalProps={qrCodeModalProps} />
      <UpdateToDemandeModal updateToDemandeModalProps={updateToDemandeModalProps} />
      <UpdateMultiToDemandeModal
        updateMultiToDemandeModalProps={updateMultiToDemandeModalProps}
      />
      <DeleteModal deleteBillOfLadingProps={deleteBillOfLadingProps} />
      <ToastUpdateUserSuccess toastUpdateUserSuccessProps={toastUpdateUserSuccessProps} />
      <ToastDeleteSuccess toastDeleteSuccessProps={toastDeleteSuccessProps} />
      <ToastError toastErrorProps={toastErrorProps} />

      <ToastInfo toastInfoProps={toastInfoProps} />

      <Modal show={showUpdatePalette} onHide={handleCloseUpdatePalette}>
        <Modal.Header closeButton>
          <Modal.Title>Modifier le nombre de palette</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {' '}
          <Form.Group className='mb-3 px-2'>
            <Form.Label className='d-flex'> Nombre de palette</Form.Label>
            <Form.Control
              id='nombrePalette'
              name='nombrePalette'
              type='number'
              placeholder='nombre de palette à charger'
              style={{ width: 'auto' }}
              value={selectedConnaissement?.nombreColisAEmbarquer}
              onChange={(e: any) => {
                const nb = parseInt(e.currentTarget.value)
                setSelectedConnaissement({
                  ...selectedConnaissement,
                  nombreColisAEmbarquer: nb,
                })

                const order = connaissementData?.map((order: any) => {
                  if (order.id === selectedConnaissement?.id) {
                    return {
                      ...order,
                      nombreColisAEmbarquer: nb,
                    }
                  }
                  return order
                })
                setConnaissementData(order)
                setNbPalette(nb)
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseUpdatePalette}>
            Annuler
          </Button>
          <Button
            variant='primary'
            onClick={() =>
              updateNbPaletteConnaissement(dataStore.access_token, selectedConnaissement)
            }
          >
            Valider
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
