import React from 'react';
import './App.css';
import { Col, Row } from 'react-bootstrap';
import {  Outlet, useLocation, useNavigate } from 'react-router-dom';
import Header from './component/layout/Header';
import SideMenu from './component/layout/SideMenu';
import VersionService from './services/version/VersionService';
import userStore from './stores/userStore';
import { ToastUpdateUserSuccess } from './component/ui/Toast/Toastes';
import Order from './pages/private/Order';
import BottomNavBar from './component/layout/BottomNavBar';
import { _getShipments } from './utils/api/apiControlerFunctions';

function App() {

  const dataStore = userStore((state: any) => state)
  const navigate = useNavigate()
  const location = useLocation()
  const isHomePage = location.pathname === '/'

  const isTransporter = dataStore?.roles && dataStore.roles[0]?.name ==='transporteur'

  const [companiesData, setCompaniesData] = React.useState<any>();
  const [naviresData, setNaviresData] = React.useState<any>([]);
  const [userData, setUserData] = React.useState<any>();

  const [showUpdateSuccess, setShowUpdateSuccess] = React.useState<boolean>(false);
  const toggleShowUpdateSuccess = () => setShowUpdateSuccess(!showUpdateSuccess);



  React.useEffect(() => {
    getVersion()
    if (!dataStore.token || dataStore.token === undefined || dataStore.token === null) {
      navigate('/connexion')
    }
    
    if(isTransporter){
      navigate('/connaissements')
    }
    if(isHomePage){
      navigate('/factures')
    }
  }, [])

  React.useEffect(() => {
    _getShipments(dataStore.access_token, setNaviresData)
  }, [dataStore.access_token])

  const getVersion = async () => {
    try {
      await VersionService.getVersion()
    } catch (error) {
      console.log(error)
    }
  }

const sideMenuProps = {}
const toastUpdateUserSuccessProps = { showUpdateSuccess, toggleShowUpdateSuccess }

  return (
    <div className=' pe-0'>
      <Header />
      <div className=''>
        <Row className='gx-0'>
          <Col className='vh-100 d-none d-md-block' xs={2} sm={2} md={2} lg={2} xl={2}>
            <SideMenu {...sideMenuProps} />
          </Col>
          <Col xs={12} sm={12} md={10} lg={10} xl={10} className='ps-0 pe-0'>

            <Outlet
              context={{
                userData,
                setUserData,
                companiesData,
                setCompaniesData,
                setShowUpdateSuccess,
                naviresData,
              }}
            />
          </Col>
        </Row>
        <BottomNavBar />
      </div>
      <ToastUpdateUserSuccess toastUpdateUserSuccessProps={toastUpdateUserSuccessProps} />
    </div>
  )
}

export default App;
