import React from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import userStore from '../../stores/userStore';
import { _getOrdersData2 } from '../../utils/functions';
import { useOutletContext } from 'react-router-dom';

export default function OrderFilter({orderFilterProps}: any) {

 

    const dataStore = userStore((state: any) => state)
    const {
      handleSelectAll,
      dataOrder,
      ordersForConnaissement,
      handlefilteredOrder,
      filteringData,
      setFilteringData,
      filteredOrder,
      isFiltering,
      setIsFiltering,
      currentPage,
      setDataOrder,
      setTotalPages,
      isLoading,
      setIsLoading,
      setErrorOrderMessage,
    } = orderFilterProps


    const status = [
      'A_PLANIFIER',
      'A_DEPLANIFIER',
      'BROUILLON',
      'DEMANDE',
      'DEMANDE_REFUSEE',
      'SAISIE',
      'OFFICIALISE',
      'OFFICIALISE_SOUS_RESERVE',
      'MODIFIE',
      'PRIS_EN_CHARGE',
      'EMBARQUE',
      'EMBARQUEMENT_REFUSE',
      'TRANSFERE',
      'ANNULE',
    ]
    //if fitteringData is empty
    const isEmpty =
      filteringData.date_creation === '' &&
      filteringData.referenceHorsRevatua === '' &&
      filteringData.destinataire_denomination === '' &&
      filteringData.numeroVoyage === '' &&
      filteringData.statut_revatua === '' &&
      filteringData.bateau === '' &&
      filteringData.ileArrivee === ''

  
    return (
    <thead className=''>
    <tr>
      <th className='p-1 p-sm-2'>
        <Form.Check
          type='checkbox'
          id={`allCheck`}
          onChange={handleSelectAll}
          checked={
            dataOrder?.length > 0 && ordersForConnaissement?.length === dataOrder?.length
          }
          required
        />
      </th>
      <th className='responsive-font-small p-1 p-sm-2'>
        {' '}
        <Form.Control
          className=' border'
          type='text'
          autoComplete='on'
          placeholder='Date'
          name='date_creation'
          value={filteringData.date_creation || ''}
          onChange={handlefilteredOrder}
        />
      </th>
      <th className='responsive-font-small p-1 p-sm-2'>
        {' '}
        <Form.Control
          className=' border'
          type='text'
          autoComplete='on'
          placeholder='Numéro Facture'
          name='referenceHorsRevatua'
          value={filteringData.referenceHorsRevatua}
          onChange={handlefilteredOrder}
        />
      </th>
      <th className='responsive-font-small p-1 p-sm-2'>
        {' '}
        <Form.Control
          className=' border'
          type='text'
          autoComplete='on'
          placeholder='Client'
          name='destinataire_denomination'
          value={filteringData.destinataire_denomination || ''}
          onChange={handlefilteredOrder}
        />
      </th>
      <th className='responsive-font-small p-1 p-sm-2'>
        {' '}
        <Form.Control
          className=' border'
          type='text'
          autoComplete='on'
          placeholder='N°voyage'
          name='numeroVoyage'
          value={filteringData.numeroVoyage || ''}
          onChange={handlefilteredOrder}
        />
      </th>
      <th className='responsive-font-small p-1 p-sm-2'>
        {' '}
        <Form.Select
          name='evenementConnaissement'
          value={filteringData.statut_revatua || ''}
          onChange={(e) => {
            const updatedFilteringData = {
              ...filteringData,
              statut_revatua: e.currentTarget.value,
            }
            setFilteringData(updatedFilteringData)
          }}
          aria-label='zone'
          required
        >
          <option value='' className='text-ui-second'>
            Statut
          </option>
          {status?.map((etat: any, index: any) => (
            <option key={index} value={etat}>
              {etat}
            </option>
          ))}
        </Form.Select>
      </th>
      <th className='responsive-font-small p-1 p-sm-2'>
        {' '}
        <Form.Control
          className=' border'
          type='text'
          autoComplete='on'
          placeholder='Navire'
          name='bateau'
          value={filteringData.bateau || ''}
          onChange={handlefilteredOrder}
        />
        
      </th>
      <th className='responsive-font-small p-1 p-sm-2'>
        <Form.Control
          className=' border'
          type='text'
          autoComplete='on'
          placeholder='Arrivée'
          name='ileArrivee'
          value={filteringData.ileArrivee || ''}
          onChange={handlefilteredOrder}
        />
      </th>
      <th className='responsive-font-small p-1 p-sm-2'>
        <Button
          className='d-flex align-items-center font-85 bg-remora-primary border-remora-primary'
          onClick={() => filteredOrder(dataStore.token, filteringData)}
          disabled={isEmpty}
        >
          {isLoading ? 
          <Spinner size="sm" animation="border" role="status" className='me-2'/>
          : 
          <i className='ri-search-line me-0  me-md-2'></i>
        }
          <span className='d-none d-md-block'>Rechercher</span>
        </Button>
        {isFiltering && (
          <span
            className='pointer'
            onClick={() => {
              setFilteringData({
                date_creation: '',
                referenceHorsRevatua: '',
                destinataire_denomination: '',
                numeroVoyage: '',
                statut_revatua: '',
                ileArrivee: '',
              })
              _getOrdersData2(
                dataStore.token,
                currentPage,
                setDataOrder,
                setTotalPages,
                setIsLoading,
                setErrorOrderMessage
              )
              setIsFiltering(false)
            }}
          >
            <i className='ri-close-line fs-5'></i>
            <u>Réinitialiser</u>
          </span>
        )}
      </th>
    </tr>
  </thead>
  )
}
