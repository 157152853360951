import { Modal, Container, Button, Row, Col } from 'react-bootstrap'
import userStore from '../../../stores/userStore'
import OrdersService from '../../../services/orders/OrdersService'
import React from 'react'
import { Tag } from 'rsuite'
import { _tagStatus } from '../../../utils/functions'
import { QRCode } from 'antd'
import ConnaissementServices from '../../../services/connaissements/ConnaissementServices'

export function DeleteModal({ deleteBillOfLadingProps }: any) {
  const {
    showDeleteModal,
    handleCloseDeleteModal,
    selectedConnaissement,
    deleteBrouillonConnaissement,
    idsOrder,
    setIsError,
    toggleShowOrderError,
  } = deleteBillOfLadingProps
  const dataStore = userStore((state: any) => state)

  React.useEffect(() => {
    if (
      showDeleteModal
    ) {
      getOrdersByOneIdBill(selectedConnaissement?.id)
    }
  }, [showDeleteModal])

  const getOrdersByOneIdBill = (id: number) => {
    setIsError({
      error: false,
      message: '',
    })

    try {
      const responseOrder: any = OrdersService.getOrdersByIdConnaissement(dataStore.token, id)
        .then((response: any) => {

          const checkedOrder = response?.data?.data?.map((order: any) => {
            return idsOrder.push(order?.id)
          })
          console.log(checkedOrder)
        })
        .catch((error: any) => {
          console.log(error)
          setIsError({
            error: true,
            message: error?.response?.data?.message,
          })
          toggleShowOrderError()
        })
      console.log(responseOrder)

    } catch (error) {
      console.log(error)
    }
  }

  const handleDeleteOrder = (token: string) => {
    setIsError({
      error: false,
      message: '',
    })
    const bodyData = {
      statut_revatua: 'A_PLANIFIER',
      numeroVoyage: '',
    }

    try {
      const orderPromises = idsOrder?.map((id: number) =>
        OrdersService.updateOrder(token, bodyData, id).catch((error: any) => {
          if (error) {
            console.log(error)
            setIsError({
              error: true,
              message: error?.response?.data?.message,
            })
            toggleShowOrderError()
          }
        })
      )
      console.log(orderPromises)
      // await Promise.all([
      //   ...(orderPromises || [])
      // ]);

      deleteBrouillonConnaissement(dataStore.access_token, selectedConnaissement?.id)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal size='lg' show={showDeleteModal} onHide={handleCloseDeleteModal}>
      <Modal.Header className='border border-3 border-danger border-bottom-0'>
        <Modal.Title>Supprimer le connaissement</Modal.Title>
      </Modal.Header>

      <Modal.Body className='border border-3 border-danger border-bottom-0 border-top-0'>
        <Container fluid>
          &Ecirc;tes vous sur de vouloir supprimer le connaissement{' '}
          <b>
            N°{' '}
            {selectedConnaissement?.numero
              ? selectedConnaissement?.numero
              : selectedConnaissement?.id}
          </b>{' '}
          ?
        </Container>
      </Modal.Body>
      <Modal.Footer className='border border-3 border-danger border-top-0'>
        <Button className='' variant='secondary' onClick={handleCloseDeleteModal}>
          <i className='ri-close-circle-line me-2'></i> Annuler
        </Button>
        <Button
          variant='danger'
          onClick={() =>
            handleDeleteOrder(dataStore.token)
          }
        >
          <i className='ri-delete-bin-2-line me-2'></i> Valider
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export function UpdateMultiToDemandeModal({ updateMultiToDemandeModalProps }: any) {
  const {
    showUpdateMultiToDemandeModal,
    handleCloseUpdateMultiToDemandeModal,
    checkedConnaissement,
    setCheckedConnaissement,
    connaissementDataTable,
    toggleShowUpdateSuccess,
    setIsError,
    idsOrder,
    setIdsOrder,
  } = updateMultiToDemandeModalProps
  const dataStore = userStore((state: any) => state)
  const dataStoreConnaissement = userStore((state: any) => state)

  const handleUpdateMultiOrder = async () => {
    try {
      // Mise à jour des commandes
      updateOrderRemora(dataStore.token)

      // Mise à jour des connaissements
      updateConnaissRevatua()

      // Si tout a réussi
      connaissementDataTable()
      toggleShowUpdateSuccess()
      handleCloseUpdateMultiToDemandeModal()
      setCheckedConnaissement([])
      setIsError({
        error: false,
        message: '',
      })
    } catch (error: any) {
      // Gestion des erreurs
      console.error(error)
      setIsError({
        error: true,
        message: error?.response?.data?.message || "Une erreur s'est produite",
      })
    }
  }

  const updateOrderRemora = async (token: string) => {
    const bodyData = {
      statut_revatua: 'DEMANDE',
    }
    try {
      const orderPromises = idsOrder?.map((id: number) =>
        OrdersService.updateOrder(token, bodyData, id).catch((error: any) => {
          if (error) {
            console.log(error)
            setIsError({
              error: true,
              message: error?.response?.data?.message,
            })
          }
        })
      )

      await Promise.all([...(orderPromises || [])])
    } catch (error) {
      console.log(error)
    }
  }

  const updateConnaissRevatua = async () => {
    const bodyDataConnaissement = {
      evenementConnaissementEnum: 'DEMANDE',
      demandeParArmateur: false,
    }

    try {
      const connaissementPromises = checkedConnaissement?.map((id: number) =>
        ConnaissementServices.updateConnaissement(
          dataStoreConnaissement?.access_token,
          bodyDataConnaissement,
          id
        ).catch((error: any) => {
          if (error) {
            console.log(error)
            setIsError({
              error: true,
              message: error?.response?.data?.message,
            })
          }
        })
      )

      await Promise.all([...(connaissementPromises || [])])
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal
      size='lg'
      show={showUpdateMultiToDemandeModal}
      onHide={handleCloseUpdateMultiToDemandeModal}
    >
      <Modal.Header className=''>
        <Modal.Title>Valider plusieurs brouillons</Modal.Title>
      </Modal.Header>

      <Modal.Body className=''>
        <Container fluid>
          Voulez-vous valider ces brouillons de connaissement{' '}
          {checkedConnaissement?.map((id: number, indx: number) => (
            <b key={id}>
              N° {id} {indx + 1 !== checkedConnaissement?.length && ', '}
            </b>
          ))}
        </Container>
      </Modal.Body>
      <Modal.Footer className=''>
        <Button
          className=''
          variant='secondary'
          onClick={() => {
            setIdsOrder([])
            setCheckedConnaissement([])
            handleCloseUpdateMultiToDemandeModal()
          }}
        >
          <i className='ri-close-circle-line me-2'></i> Annuler
        </Button>
        <Button variant='success' onClick={() => handleUpdateMultiOrder()}>
          <i className='ri-check-double-line me-2'></i> Valider
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export function UpdateToDemandeModal({ updateToDemandeModalProps }: any) {
  const {
    showUpdateToDemandeModal,
    handleCloseUpdateToDemandeModal,
    selectedConnaissement,
    updateBrouillonConnaissement,
    idsOrder,
    setIsError,
    toggleShowOrderError,
  } = updateToDemandeModalProps
  const dataStore = userStore((state: any) => state)

  React.useEffect(() => {
    if (showUpdateToDemandeModal) {
      getOrderSByIDBill()
    }
  }, [showUpdateToDemandeModal])

  const getOrderSByIDBill = async () => {
    try {
      const responseOrder = await OrdersService.getOrdersByIdConnaissement(
        dataStore.token,
        selectedConnaissement?.id
      )
        .then((response: any) => {
          const checkedOrder = response?.data?.data?.map((order: any) => {
            return idsOrder.push(order?.id)
          })
          console.log(checkedOrder)
        })
        .catch((error: any) => {
          setIsError({
            error: true,
            message: 'Revatua : ' + error?.response?.data?.message,
          })
          toggleShowOrderError()
        })
      console.log(responseOrder)
    } catch (error) {
      console.log(error)
    }
  }

  const handleUpdateOrder = (token: string) => {
    const bodyData = {
      statut_revatua: 'DEMANDE',
    }

    try {
      const orderPromises = idsOrder?.map((id: number) =>
        OrdersService.updateOrder(token, bodyData, id).catch((error: any) => {
          if (error) {
            console.log(error)
          }
        })
      )
      console.log(orderPromises)
      // await Promise.all([
      //   ...(orderPromises || [])
      // ]);

      updateBrouillonConnaissement(dataStore.access_token, selectedConnaissement?.id)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal size='lg' show={showUpdateToDemandeModal} onHide={handleCloseUpdateToDemandeModal}>
      <Modal.Header className=''>
        <Modal.Title>Validation du brouillon</Modal.Title>
      </Modal.Header>

      <Modal.Body className=''>
        <Container fluid>
          Voulez-vous valider le brouillon de connaissement{' '}
          <b>
            N°{' '}
            {selectedConnaissement?.numero
              ? selectedConnaissement?.numero
              : selectedConnaissement?.id}
          </b>{' '}
          ?
          <br />
          {selectedConnaissement?.destinataire?.denomination}
        </Container>
      </Modal.Body>
      <Modal.Footer className=''>
        <Button className='' variant='secondary' onClick={handleCloseUpdateToDemandeModal}>
          <i className='ri-close-circle-line me-2'></i> Annuler
        </Button>
        <Button variant='success' onClick={() => handleUpdateOrder(dataStore?.token)}>
          <i className='ri-check-line me-2'></i> Valider
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export const QrcodeConnaissementModal = ({ qrCodeModalProps }: any) => {
  const { showQrcode, handleCloseQrcode, selectedConnaissement } = qrCodeModalProps
  return (
    <Modal show={showQrcode} onHide={handleCloseQrcode}>
      <Modal.Header>
        <Container fluid>
          <Row>
            <Col xs={0} md={2} className='m-auto text-start responsive-font-small'></Col>
            <Col xs={12} md={8} className='m-auto responsive-font-medium text-center'>
              <div>{selectedConnaissement?.voyage?.nomNavire}</div>
              <div className='font-75'>
                {selectedConnaissement?.armateur !== null && (
                  <>
                    BP {selectedConnaissement?.armateur?.boitePostale}{' '}
                    {selectedConnaissement?.armateur?.commune?.codePostal}{' '}
                    {selectedConnaissement?.armateur?.commune?.nom} <strong>Tél : </strong>{' '}
                    {selectedConnaissement?.armateur?.telephone}
                  </>
                )}
              </div>
            </Col>
            <Col
              xs={12}
              md={2}
              className='d-flex justify-content-center py-2 py-md-0  text-md-end responsive-font-small'
            ></Col>
          </Row>
          <Row>
            <Col xs={12} className='m-auto text-center responsive-font-medium mb-3'>
              <strong>{selectedConnaissement?.destinataire?.denomination}</strong>
            </Col>
          </Row>
          <Row className='responsive-font-small mb-3'>
              <Col xs={12} className='m-auto responsive-font-medium text-center mb-3'>
              <Tag
                className='responsive-font-small'
                size='sm'
                color={_tagStatus(selectedConnaissement?.dernierEtat?.evenementConnaissement)}
              >
                {selectedConnaissement?.dernierEtat?.evenementConnaissement}
              </Tag>
            </Col>
            <Col xs={6} className='m-auto text-start responsive-font-small font-75'>
              <strong>Date</strong> :{' '}
              {new Date(selectedConnaissement.voyage?.dateDepart).toLocaleDateString('fr-FR', {
                timeZone: 'UTC',
              })}{' '}
              {selectedConnaissement.voyage?.heureDepart?.hour && (
                <span>
                  {selectedConnaissement.voyage?.heureDepart?.hour} :{' '}
                  {selectedConnaissement.voyage?.heureDepart?.minute}
                </span>
              )}
            </Col>
            <Col xs={6} className='m-auto text-end responsive-font-small  font-75'>
              <strong>N°Connaiss...</strong> : {selectedConnaissement?.numero}
            </Col>
            {selectedConnaissement?.dernierEtat?.motif && (
              <Col xs={12} className='m-auto text-center responsive-font-small font-85  mb-3'>
                <strong>Motif</strong> : {selectedConnaissement?.dernierEtat?.motif}
              </Col>
            )}
            <Col xs={6} className='m-auto text-start responsive-font-small font-85'>
              <strong>N° Facture</strong> : {selectedConnaissement?.referenceHorsRevatua}
            </Col>
            <Col xs={6} className='m-auto text-end responsive-font-small font-85'>
              <strong>Nb palette</strong> : {selectedConnaissement?.nombreColisAEmbarquer}
            </Col>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body className='d-flex justify-content-center'>
        {selectedConnaissement?.numero !== null && (
          <QRCode
            value={selectedConnaissement?.numero}
            size={320}
            bordered={true}
            bgColor={'#ffffff'}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className='w-100' variant='primary' onClick={handleCloseQrcode}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
