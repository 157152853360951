import React from 'react'

export default function BillOfTableTitle({isTransporter}: any) {

  return (
    <thead>
    <tr>
      <th></th>
      <th>N°Connaiss...</th>
      {isTransporter && <th>Compagnie</th>}
      <th>Client</th>
      <th>Navire</th>
      <th>Statut</th>
      <th>Date départ</th>
      <th>Ile d'arrivée</th>
      <th>Date Arrivée</th>
      <th>Action</th>
    </tr>
  </thead>
  )
}
